import Home from "./Components/Home";
import React, { useState } from "react";
import {Routes, Route} from "react-router-dom";
import Results from "./Components/Results";
import NotFound from "./Components/NotFound";
import './App.css';
import Footer from "./Components/Footer";
import Disclaimer from "./Components/OtherPages/Disclaimer";
import OperetorServices from "./Components/OtherPages/OperetorServices";
import ContactUs from "./Components/OtherPages/ContactUs";
import AboutUs from "./Components/OtherPages/AboutUs";
import TechDescription from "./Components/OtherPages/TechDescription";
import Contactme from "./Components/Contactme";
import Payments from "./Components/OtherPages/Payments";
import ApiPage from "./Components/OtherPages/ApiPage";
import PrivateArea from "./Components/OtherPages/PrivateAera";

function App() {

  const [autoComplete, setAutoComplete] = useState(false);

  const setFilled = async () => {
    await setAutoComplete(!autoComplete)
  }

  return (
    <div style={{height: '100%'}}>
      <Routes>
        <Route exact path="/" element={<Home filled={autoComplete} f={() => setFilled()}/>} />
        <Route exact path="/results" element={<Results f={() => setFilled()}/>} />
        <Route exact path="/disclaimer" element={<Disclaimer />} />
        <Route exact path="/opservices" element={<OperetorServices />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/technologies" element={<TechDescription />} />
        <Route exact path="/contactme" element={<Contactme />} />
        <Route exact path="/payments" element={<Payments />} />
        <Route exact path='/FiberFinderApi' element={<ApiPage/>} />
        <Route exact path="/private" element={<PrivateArea />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      <Footer />
      <div className="bg"></div>
    </div>
  );
}

export default App;
